import React from "react"
import styled from "styled-components"
import { breakpoints } from "@breakpoints"

import Breadcrumbs from "../../common/Breadcrumbs"
import { GridContainer } from "../../styled/GridContainer"
import { H2, H3 } from "../../styled/headers"

const StyledWrapper = styled.div`
    margin-top: -200px;
    padding: 200px 0 80px 0;
    background-color: var(--medium-gray);

    ${breakpoints.md} {
        margin-top: -220px;
        padding: 220px 0 150px 0;
    }
`

const StyledText = styled.div`
    margin-top: 30px;

    ${breakpoints.md} {
        margin-top: 60px;
    }
`

const Header = ({ place, frontPage, currentPage, parentPage }: props) => {
    return (
        <StyledWrapper>
            <GridContainer>
                <Breadcrumbs
                    currentPage={currentPage}
                    parentPage={{
                        name: parentPage.name,
                        slug: `/${parentPage.slug}`,
                    }}
                    frontPage={frontPage}
                />
                <StyledText>
                    <H2 dangerouslySetInnerHTML={{ __html: currentPage }} />
                    <H3 dangerouslySetInnerHTML={{ __html: place }} />
                </StyledText>
            </GridContainer>
        </StyledWrapper>
    )
}

export default Header

interface props {
    place: string
    frontPage: string
    currentPage: string
    parentPage: {
        name: string
        slug: string
    }
}
