import React, { useContext } from "react"
import styled from "styled-components"
import { gatsbyImage } from "@types"
import { breakpoints } from "@breakpoints"
import { formatDate } from "../../../../utils/formatDate"
import { Button } from "../../../common/Button"
import { useBasicQuery } from "@useBasicQuery"
import { UserContext } from "../../../context/UserContext"
import { ModalContext } from "../../../context/ModalContext"
import Instructor from "./Instructor"
import Socials from "./Socials"
import Spinner from "../../../common/Spinner"

const StyledWrapper = styled.aside`
    color: #fff;
    border-radius: 20px;
    background-color: var(--dark-blue);
    padding: 30px 20px;

    ${breakpoints.lg} {
        padding: 70px 30px;
    }
`

const StyledButtonWrapper = styled.div<{ error: boolean }>`
    display: flex;
    align-items: ${({ error }) => (error ? "flex-start" : "center")};
    flex-direction: ${({ error }) => (error ? "column" : "row")};

    svg {
        margin-left: 10px;
    }
`

const StyledPrice = styled.div`
    font-size: var(--big-font);
    font-weight: 700;
    margin-bottom: 20px;

    ${breakpoints.sm} {
        font-size: 50px;
    }

    ${breakpoints.md} {
        font-size: var(--big-font);
    }

    ${breakpoints.lg} {
        font-size: 50px;
    }

    span {
        color: var(--light-blue);
    }
`

const StyledInstructors = styled.h3`
    margin-bottom: 20px;
    color: var(--light-blue);
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1em;
    font-size: var(--small-font);

    ${breakpoints.sm} {
        font-size: var(--big-font);
        margin-bottom: 40px;
    }
`

const StyledItalic = styled.span`
    display: flex;
    font-size: var(--small-font);
    font-style: italic;
    font-weight: 300;

    ${breakpoints.sm} {
        font-size: var(--big-font);
    }
`

const StyledDate = styled.span`
    display: block;
    font-size: var(--small-font);
    font-weight: 700;
    color: var(--light-blue);
    margin: 20px 0;

    ${breakpoints.sm} {
        font-size: var(--medium-font);
    }
`

const StyledError = styled.span`
    margin-top: 10px;
    font-weight: 700;
    color: var(--red);
`

const StyledSeparator = styled.div<{ bottom: boolean }>`
    position: relative;
    padding: 20px 0;
    margin: 30px 0;
    margin-bottom: ${({ bottom }) => (bottom ? "30px" : "0")};

    ${breakpoints.md} {
        margin: 40px 0;
        padding: 40px 0;
        margin-bottom: ${({ bottom }) => (bottom ? "40px" : "0")};
    }

    &:after {
        height: 1px;
        top: 0;
    }

    &:before {
        height: ${({ bottom }) => (bottom ? "1px" : "0px")};
        bottom: 0;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        left: -30px;
        width: calc(100% + 60px);
        background-color: var(--light-gray);
        opacity: 0.35;
    }
`

const Sidebar = ({
    addOrder,
    price,
    date,
    stock,
    place,
    instructors,
    loading,
    creatingOrder,
    error,
    slug,
}: props) => {
    const {
        general: {
            acf: { buyNow },
        },
        singleproduct: {
            acf: {
                remainingTrainingText,
                extraMaterials,
                instructors: instructorsHeader,
                socialsShare,
            },
        },
    } = useBasicQuery()
    const { userData } = useContext<any>(UserContext)
    const { setNavigateAfterClosing, setModalActive } =
        useContext<any>(ModalContext)

    const trainingExpired =
        new Date() > new Date(formatDate(date.start).dateToCompare)
    const productButton = userData ? (
        <>
            {stock !== 0 && userData && (
                <StyledButtonWrapper error={error}>
                    <Button
                        cypressId="buyTrainingButton"
                        onClick={() => addOrder()}
                        icon="cart"
                        loading={creatingOrder}
                    >
                        {buyNow}
                    </Button>

                    {error && (
                        <StyledError>
                            Coś poszło nie tak, produkt nie został dodany do
                            koszyka. Spróbuj ponownie za chwilę.
                        </StyledError>
                    )}
                </StyledButtonWrapper>
            )}
        </>
    ) : (
        <>
            {stock !== 0 && (
                <Button
                    cypressId="buyTrainingButton"
                    onClick={() => {
                        setModalActive(true)
                        setNavigateAfterClosing({ href: slug })
                    }}
                    icon="cart"
                >
                    {buyNow}
                </Button>
            )}
        </>
    )
    return (
        <StyledWrapper>
            <StyledPrice>
                {`${price.label} `}
                <span dangerouslySetInnerHTML={{ __html: price.value }} />
            </StyledPrice>
            {!trainingExpired && (
                <StyledItalic>
                    {remainingTrainingText}
                    {stock || stock === 0 ? (
                        ` ${stock}`
                    ) : (
                        <div className="ml-2">
                            <Spinner />
                        </div>
                    )}
                </StyledItalic>
            )}

            <StyledDate>
                {formatDate(date.start).dateToDisplay} - {date.end}
                <div dangerouslySetInnerHTML={{ __html: place }} />
            </StyledDate>
            {!loading ? (
                <>
                    {trainingExpired
                        ? "Czas kupna szkolenia upłynął!"
                        : productButton}
                </>
            ) : (
                <>{(stock || stock === 0) && <Spinner />}</>
            )}
            {/* <StyledSeparator bottom={instructors !== null}>
                <StyledItalic
                    dangerouslySetInnerHTML={{ __html: extraMaterials }}
                />
            </StyledSeparator> */}

            {instructors && (
                <StyledInstructors
                    dangerouslySetInnerHTML={{ __html: instructorsHeader }}
                />
            )}

            {instructors?.map(
                ({ id, title, content, acf: { position, image } }) => (
                    <Instructor
                        key={id}
                        name={title}
                        desc={content}
                        position={position}
                        image={image}
                    />
                )
            )}
            <Socials header={socialsShare} />
        </StyledWrapper>
    )
}

export default Sidebar

interface props {
    creatingOrder: boolean
    addOrder: () => void
    place: string
    error: boolean
    stock?: number
    loading: boolean
    slug: string
    instructors?: Array<{
        id: string
        title: string
        content: string
        acf: {
            position: string
            image: gatsbyImage
        }
    }>
    price: {
        label: string
        value: string
    }
    date: {
        start: string
        end: string
    }
}
