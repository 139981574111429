import React from "react"
import styled from "styled-components"
import Quote from "@icons/quote.svg"
import { H4 } from "../../styled/headers"
import { breakpoints } from "@breakpoints"

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`

const StyledContent = styled.div`
    font-style: italic;
    font-size: var(--base-font);
    margin: 20px 0;
`

const StyledHeader = styled.div`
    margin-top: auto;
`

const StyledCity = styled.span`
    color: var(--gray);
    font-size: var(--small-font);

    ${breakpoints.lg} {
        font-size: var(--base-font);
    }
`

const Review = ({ content, author, city }: props) => {
    return (
        <StyledWrapper>
            <Quote />
            <StyledContent dangerouslySetInnerHTML={{ __html: content }} />
            <StyledHeader>
                <H4 dangerouslySetInnerHTML={{ __html: author }} />
            </StyledHeader>
            <StyledCity dangerouslySetInnerHTML={{ __html: city }} />
        </StyledWrapper>
    )
}

export default Review

interface props {
    content: string
    author: string
    city: string
}
