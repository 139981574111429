import { graphql, useStaticQuery } from "gatsby"

export const useQuery = () => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    singleproduct {
                        acf {
                            reviewsHeader
                            reviewsSubheader
                        }
                    }
                }
            }
        `
    )
    return data.wp.singleproduct.acf
}
