export const formatDate = (date: string) => {
    const year = date ? date.slice(6, 10) : ""
    const month = date ? date.slice(3, 5) : ""
    const day = date ? date.slice(0, 2) : ""
    const dateToCompare = `${year}-${month}-${day}`
    const dateToDisplay = `${day}.${month}`

    return {
        dateToCompare: dateToCompare,
        dateToDisplay: dateToDisplay,
    }
}
