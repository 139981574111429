import React from "react"
import styled from "styled-components"
import { useQuery } from "./useQuery"
import { breakpoints } from "@breakpoints"
import { GridContainer } from "../../styled/GridContainer"
import Headline from "../../common/Headline"
import Review from "./Review"
import LazyLoad from "react-lazyload"

const Wrapper = styled.section`
    background-color: var(--medium-gray);
    padding: 40px 0;

    ${breakpoints.lg} {
        padding: 80px 0;
    }
`

const Reviews = ({ reviews }: props) => {
    const {
        reviewsSubheader,
        reviewsHeader,
    }: { reviewsSubheader: string; reviewsHeader: string } = useQuery()
    if (!reviews) return null
    return (
        <LazyLoad>
            <Wrapper>
                <GridContainer>
                    <Headline
                        subheader={reviewsSubheader}
                        header={reviewsHeader}
                    />
                    <div className="grid md:grid-cols-3 gap-10 md:gap-20">
                        {reviews?.map(({ author, content, city }) => (
                            <Review
                                key={author}
                                author={author}
                                content={content}
                                city={city}
                            />
                        ))}
                    </div>
                </GridContainer>
            </Wrapper>
        </LazyLoad>
    )
}

export default Reviews

interface props {
    reviews?: Array<{
        author: string
        content: string
        city: string
    }>
}
