import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { gatsbyImage } from "@types"
import { breakpoints } from "@breakpoints"

import { GridContainer } from "../../styled/GridContainer"
import Sidebar from "./sidebar/Sidebar"
import { Content as ContentWrapper } from "../../styled/Content"

const StyledWrapper = styled.div`
    margin-top: -60px;
    margin-bottom: 70px;

    ${breakpoints.md} {
        margin-top: -120px;
        margin-bottom: 120px;
    }
`
const StyledImageWrapper = styled.div`
    .gatsby-image-wrapper {
        border-radius: 20px;
    }
`

const StyledGrid = styled.div`
    align-items: flex-start;
`

const StyledTextWrapper = styled.div`
    line-height: 2;
    font-size: var(--base-font);

    ${breakpoints.sm} {
        padding-top: 50px;
    }
`

const Content = ({
    addOrder,
    image,
    content,
    place,
    price,
    date,
    stock,
    instructors,
    type,
    loading,
    creatingOrder,
    error,
    slug,
}: props) => {
    return (
        <GridContainer>
            <StyledWrapper>
                <StyledGrid className="grid md:grid-cols-3 gap-10">
                    <div className="md:col-span-2">
                        <StyledImageWrapper>
                            <GatsbyImage
                                alt=""
                                image={
                                    image.localFile.childImageSharp
                                        .gatsbyImageData
                                }
                            />
                            <StyledTextWrapper>
                                <ContentWrapper
                                    dangerouslySetInnerHTML={{
                                        __html: content,
                                    }}
                                />
                            </StyledTextWrapper>
                        </StyledImageWrapper>
                    </div>
                    {type === "paid-training" && (
                        <Sidebar
                            slug={slug}
                            place={place}
                            stock={stock}
                            price={{ label: price.label, value: price.value }}
                            date={{ start: date.start, end: date.end }}
                            instructors={instructors}
                            addOrder={addOrder}
                            loading={loading}
                            creatingOrder={creatingOrder}
                            error={error}
                        />
                    )}
                </StyledGrid>
            </StyledWrapper>
        </GridContainer>
    )
}

export default Content

interface props {
    addOrder: () => void
    creatingOrder: boolean
    image: gatsbyImage
    content: string
    place: string
    error: boolean
    stock?: number
    slug: string
    loading: boolean
    instructors: Array<{
        id: string
        title: string
        content: string
        acf: {
            position: string
            image: gatsbyImage
        }
    }>
    type: string
    price: {
        label: string
        value: string
    }
    date: {
        start: string
        end: string
    }
}
