export const calculateStock = (
    productQuantity: number,
    productName: string,
    productsInCart: Array<{ name: string; quantity: number }>
) => {
    let productQuantityInCart: number = 0
    productsInCart?.forEach(
        ({ name, quantity }: { name: string; quantity: number }) => {
            if (name === productName) {
                productQuantityInCart += quantity
            }
        }
    )
    const calculatedStock = productQuantity - productQuantityInCart
    return calculatedStock < 0 ? 0 : calculatedStock
}
