import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { gatsbyImage } from "@types"
import { H4 } from "../../../styled/headers"
import { breakpoints } from "@breakpoints"

const Wrapper = styled.div`
    margin-bottom: 50px;
`

const WrapperInner = styled.div`
    display: flex;
    margin-bottom: 20px;

    .gatsby-image-wrapper {
        border-radius: 20px;
        width: 100px;
        margin-right: 20px;
    }
`

const StyledPosition = styled.span`
    font-size: var(--small-font);
    border-bottom: 2px solid var(--light-blue);
    padding-bottom: 5px;
    color: var(--gray);

    ${breakpoints.lg} {
        font-size: var(--base-font);
    }
`

const StyledDesc = styled.div`
    font-size: var(--base-font);
    opacity: 0.7;
`

const Instructor = ({ image, name, position, desc }: props) => {
    return (
        <Wrapper>
            <WrapperInner>
                <GatsbyImage
                    alt={name}
                    image={image.localFile.childImageSharp.gatsbyImageData}
                />
                <div>
                    <H4 dangerouslySetInnerHTML={{ __html: name }} white />
                    <StyledPosition
                        dangerouslySetInnerHTML={{ __html: position }}
                    />
                </div>
            </WrapperInner>
            <StyledDesc dangerouslySetInnerHTML={{ __html: desc }} />
        </Wrapper>
    )
}

export default Instructor

interface props {
    image: gatsbyImage
    name: string
    position: string
    desc: string
}
