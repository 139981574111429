import React from "react"
import styled from "styled-components"

import { Link } from "gatsby"
import { breakpoints } from "@breakpoints"

const StyledWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    font-size: var(--small-font);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    ${breakpoints.md} {
        font-size: var(--medium-font);
    }
`
const StyledSeparator = styled.div`
    margin: 0 5px;
    color: var(--gray);
`

const StyledCurretPage = styled.span`
    color: var(--gray);
`

const StyledLink = styled(props => <Link {...props} />)`
    color: var(--bronze);
`

const Breadcrumbs = ({ frontPage, parentPage, currentPage }: props) => {
    return (
        <StyledWrapper>
            <StyledLink to="/">{frontPage}</StyledLink>
            <StyledSeparator>/</StyledSeparator>
            <StyledLink to={parentPage.slug}>{parentPage.name}</StyledLink>
            <StyledSeparator>/</StyledSeparator>
            <StyledCurretPage
                dangerouslySetInnerHTML={{ __html: currentPage }}
            />
        </StyledWrapper>
    )
}

export default Breadcrumbs

interface props {
    frontPage: string
    parentPage: {
        name: string
        slug: string
    }
    currentPage: string
}
