import React from "react"
import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import Facebook from "@icons/facebook-white.svg"
import Twitter from "@icons/twitter-white.svg"

const StyledWrapper = styled.div`
    position: relative;
    padding-top: 30px;
    margin-top: 30px;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: -30px;
        width: calc(100% + 60px);
        height: 1px;
        background-color: var(--light-gray);
        opacity: 0.35;
    }
`

const StyledHeader = styled.span`
    font-size: var(--medium-font);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    ${breakpoints.lg} {
        font-size: var(--big-font);
    }
`

const StyledIconsWrapper = styled.div`
    display: flex;
`

const StyledWrapperInner = styled.a`
    display: flex;
    align-items: center;
    margin: 20px 35px 0 0;
`

const StyledText = styled.span`
    color: var(--light-blue);
    margin-left: 10px;
    font-size: var(--small-font);

    ${breakpoints.lg} {
        font-size: var(--medium-font);
    }
`

const Socials = ({ header }: props) => {
    const url = typeof window !== "undefined" ? window.location.href : ""
    return (
        <StyledWrapper>
            <StyledHeader dangerouslySetInnerHTML={{ __html: header }} />
            <StyledIconsWrapper>
                <StyledWrapperInner
                    rel="noopener"
                    target="_blank"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                >
                    <Facebook />
                    <StyledText
                        dangerouslySetInnerHTML={{ __html: "Facebook" }}
                    />
                </StyledWrapperInner>

                <StyledWrapperInner
                    rel="noopener"
                    target="_blank"
                    href={`https://twitter.com/intent/tweet?url=${url}`}
                >
                    <Twitter />
                    <StyledText
                        dangerouslySetInnerHTML={{ __html: "Twitter" }}
                    />
                </StyledWrapperInner>
            </StyledIconsWrapper>
        </StyledWrapper>
    )
}

export default Socials

interface props {
    header: string
}
