import React, { useState, useContext } from "react"
import { navigate } from "gatsby"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { USER_ORDER, SINLGE_COMMON_PRODUCT } from "../../../consts/queries"
import { setLocalStorage } from "../../../utils/localStorage"
import axios from "../../../utils/axios"

import Template from "../Template"
import Header from "./Header"
import Content from "./Content"
import Reviews from "../../sections/reviews/Reviews"
import { useBasicQuery } from "@useBasicQuery"
import { gatsbyImage } from "@types"
import { UserContext } from "../../context/UserContext"
import { routes } from "@routes"
import { formatDate } from "../../../utils/formatDate"
import { calculateStock } from "../../../utils/calculateStock"

const CommonProduct = ({ pageContext }: props) => {
    const {
        node: {
            name,
            content,
            price,
            slug: currentSlug,
            acf: {
                place,
                bannerImage,
                image,
                dateEnd,
                dateStart,
                instructors,
                reviews,
                type,
            },
        },
        breadcrumbs: {
            acf: {
                frontPage,
                trainingParentPage: { slug, title },
            },
        },
    } = pageContext
    const [creatingOrder, setCreatingOrder] = useState(false)
    const { userData } = useContext<any>(UserContext)
    const [error, setError] = useState(false)
    const { isLoading, data } = useQuery(
        `${SINLGE_COMMON_PRODUCT} - ${name}`,
        () => axios.get(`products?slug=${currentSlug}`)
    )
    const { isLoading: isLoadingOrder, data: userOrder } = useQuery(
        USER_ORDER,
        () => axios.get(`orders?customer=${userData.id}&status=pending`),
        {
            enabled: userData ? true : false,
        }
    )

    const addItemToOrder = () => {
        return axios.put(
            `orders/${userOrder ? userOrder.data[0].id : undefined}`,
            {
                line_items: productData,
            }
        )
    }

    const createOrder = () => {
        return axios.post("/orders", {
            currency: "PLN",
            customer_id: userData.id,
            line_items: productData,
        })
    }

    const queryClient = useQueryClient()
    const mutation = useMutation(
        () =>
            userOrder && userOrder.data.length !== 0
                ? addItemToOrder()
                : createOrder(),
        {
            onMutate: () => {
                setCreatingOrder(true)
            },
            onSuccess: data => {
                setLocalStorage("orders", JSON.stringify(data.data))
                queryClient.invalidateQueries(USER_ORDER)
                setCreatingOrder(false)
                navigate(routes.cart)
            },
            onError: () => {
                setCreatingOrder(false)
                setError(true)
            },
        }
    )

    const {
        general: {
            acf: { price: priceLabel },
        },
    } = useBasicQuery()

    const productData = [
        {
            product_id: data ? data.data[0].id : null,
            quantity: 1,
            meta_data: [
                {
                    key: "slug",
                    value: `${routes.trainingsAndEvents}/${currentSlug}`,
                },
                {
                    key: "image",
                    value: image,
                },
                {
                    key: "place",
                    value: place,
                },
                {
                    key: "type",
                    value: type,
                },
                {
                    key: "date",
                    value: `${formatDate(dateStart).dateToDisplay} - ${
                        formatDate(dateEnd).dateToDisplay
                    }`,
                },
                {
                    key: "dateEnd",
                    value: dateEnd,
                },
                {
                    key: "free-delivery",
                    value: "true",
                },
            ],
        },
    ]

    return (
        <Template isFrontPage={false}>
            <section>
                <Header
                    currentPage={name}
                    frontPage={frontPage}
                    parentPage={{ name: title, slug: slug }}
                    place={place}
                />

                <Content
                    price={{ label: priceLabel, value: price }}
                    stock={
                        data
                            ? calculateStock(
                                  data.data[0].stock_quantity,
                                  data.data[0].name,
                                  userOrder?.data[0]
                                      ? userOrder.data[0].line_items
                                      : undefined
                              )
                            : undefined
                    }
                    content={content}
                    image={bannerImage}
                    place={place}
                    date={{ start: dateStart, end: dateEnd }}
                    instructors={instructors}
                    type={type}
                    slug={`${routes.trainingsAndEvents}/${currentSlug}`}
                    addOrder={mutation.mutate}
                    loading={isLoading || isLoadingOrder}
                    error={error}
                    creatingOrder={creatingOrder}
                />
                <Reviews reviews={reviews} />
            </section>
        </Template>
    )
}

export default CommonProduct

interface props {
    pageContext: {
        node: {
            id: string
            name: string
            content: string
            price: string
            slug: string
            acf: {
                type: string
                place: string
                bannerImage: gatsbyImage
                image: gatsbyImage
                dateEnd: string
                dateStart: string
                instructors: Array<{
                    id: string
                    title: string
                    content: string
                    acf: {
                        position: string
                        image: gatsbyImage
                    }
                }>
                reviews?: Array<{
                    author: string
                    content: string
                    city: string
                }>
            }
        }
        breadcrumbs: {
            acf: {
                frontPage: string
                trainingParentPage: {
                    slug: string
                    title: string
                }
            }
        }
        general: {
            acf: {
                price: string
            }
        }
    }
}
