import styled from "styled-components"

export const Content = styled.div`
    margin-top: 30px;
    a {
        color: var(--light-blue);
    }

    ul {
        li {
            padding-left: 20px;
            position: relative;
            margin: 5px 0;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 10px;
                width: 5px;
                height: 5px;
                background-color: var(--bronze);
            }

            ul {
                li {
                    &:after {
                        background-color: var(--medium-blue);
                    }
                }
            }
        }
    }
    ol {
        list-style-type: decimal;
        padding-left: 20px;

        li {
            margin: 5px 0;
        }
    }

    h2 {
        font-size: var(--big-font);
        font-weight: 700;
        color: var(--dark-blue);
        margin: 15px 0;
    }

    .inline-gatsby-image-wrapper {
        width: 100% !important;
        img {
            width: 100%;
            height: auto;
        }
    }
`
